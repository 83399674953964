<template>
  <v-col cols="12" md="8" lg="7" class="mx-auto pa-0 pa-md-4" v-if="companyModel != null">

    <v-card outlined class="mt-md-5">
      
      <v-col cols="12" class="px-3">

          <h2 class="mt-5">
            <v-icon>mdi-package-variant-plus</v-icon> 
            Créer une nouvelle entreprise
          </h2>
          <br>

          <v-divider/>

          <v-card-subtitle class="pb-0" :class="companyModel.category.iconColor+'--text'">
            <v-icon :color="companyModel.category.iconColor" class="mb-1">
              mdi-{{ companyModel.category.icon }}
            </v-icon>
            {{ companyModel.category.name }}
          </v-card-subtitle>

          <v-card-subtitle class="py-1">
            <v-icon small class="mr-1 ml-1 mb-1">mdi-circle</v-icon>
            <b class=" font-2x">{{ companyModel.name }}</b>
          </v-card-subtitle>
      </v-col>

      <v-col cols="12">

        <v-divider/>

        <v-card-title>
          <v-icon>mdi-chevron-down</v-icon> 
          Nom & description principale de votre entreprise
        </v-card-title>

        <v-text-field v-model="name"  outlined
                      placeholder="Définissez le nom de l'entreprise" 
                      label="Nom de l'entreprise"/>

        <v-textarea v-model="description"  outlined
                      placeholder="Décrivez rapidement votre entreprise" 
                      label="Description de l'entreprise"/>

        <v-divider/>


        <v-divider/>

        <!-- SURFACE ACTIVITE -->
        <div>
          <v-card-title class="pb-0">
            <v-icon>mdi-chevron-down</v-icon>
            Surface de l'activité 
            <v-btn icon x-small outlined class="ml-2"><v-icon x-small>mdi-help</v-icon></v-btn>
          </v-card-title>

          <v-card-text>
            <!-- La productivité finale de votre entreprise, dépend en partie de la surface totale dont vous disposez...
             Plus la surface est grande, plus elle rapporte.
            <br><br> -->
            <v-icon small>mdi-hand-pointing-up</v-icon>
            Définissez la surface que vous souhaitez allouer à votre activité.<br>
            <small class="amber--text">(1 employé obligatoire pour chaque parcelle supplémentaire)</small>
          </v-card-text>

          <v-chip outlined class="ma-1" color="amber">
            <v-icon small>mdi-account-tie</v-icon> {{ nbParcelles-1  }}
          </v-chip>
          <v-chip outlined class="ma-1" color="blue">
            {{ surface > 1000 ? surface / 1000 + '.000' : surface  }} m2
            = {{ surface / 10000  }} ha
          </v-chip>
          <v-chip outlined class="ma-1" color="teal">
            au prix de {{ companyModel.surfacePrice }} òto/m2
          </v-chip>
          <v-chip outlined class="ma-1" color="green">
            <b>Valeur totale : {{ budget  }} òto</b>
          </v-chip>

          <v-slider v-model="surface" inverse-label :label="surface+' m2'"
                    :min="companyModel.surfaceMin"
                    :max="companyModel.surfaceMax"
                    :step="companyModel.surfaceParcelle"/>
        </div>


        <v-row class="mt-5">
          <v-toolbar elevation="0">
            <v-spacer/>
            <v-btn class="mx-1" color="red">Annuler</v-btn>
            <v-btn class="mx-1" color="green" 
                  :disabled="benefices < 0 || !nbProdEnabledChecked"
                  @click="saveCompany">
                  Enregistrer mon entreprise !
            </v-btn>
          </v-toolbar>
        </v-row>

      </v-col>

    </v-card>
  </v-col>
</template>

<style>
.chk-preselected{
  border-color: #c76127 !important;
}
.chk-selected{
  border-color: #4caf50 !important;
}
.table-revenus td{
  padding-right: 15px;
  width:50%;
}
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'
const config = require('../config/' + process.env.NODE_ENV)


export default {
  name: 'company-form',
  components: { },
  data: () => ({
    name: "",
    description: "",
    productions: [],
    companyModel: null,
    delayCredit: 3,
    surface: 0,
    salary: 5,
    mySalary: 5,
    remiseFirstCompany: 0,
  }),
  async mounted(){
    this.init()
  },
  methods: {
    init: function(){
      if(this.$store.state.company.companyFormModel == null)
      this.$router.push('/create-company') 

      this.productions = []
      this.name = this.$store.state.company.companyFormName
      this.companyModel = this.$store.state.company.companyFormModel
      this.surface = this.$store.state.company.surface

      this.remiseFirstCompany = Math.random()

      if(this.companyModel != null && this.companyModel.productions.length > 0)
        this.productions.push(this.companyModel.productions[0]._id)
    },
    selectProduction: function(production){
      if(this.productions.indexOf(production._id) == -1){
        //if(this.productions.length+1 <= this.nbProdEnabled)
          this.productions.push(production._id)
      }else{
        this.productions.splice(this.productions.indexOf(production._id), 1)
      }
    },
    reloadCompanyModels: async function(){
      await this.$store.dispatch('company/fetchCompanyModels')
      this.init()
    },
    saveCompany: async function(){
      let params = {
        name: this.name,
        description: this.description,
        productions: this.productions,
        delayCredit: this.delayCredit,
        surface: this.surface,
        surfaceMin: this.companyModel.surfaceMin,
        surfaceMax: this.companyModel.surfaceMax,
        salaryEmployee: this.salary,
        salaryBoss: this.mySalary,
        ownModel: this.companyModel._id,
      }
      let res = await this.$store.dispatch('company/saveCompany', params)
      if(res.error == false)
        return
    }


  },
  computed: {
    monyToEuro(){
      return config.monyToEuro
    },
    budget: function(){
      return ((this.surface * this.companyModel.surfacePrice)).toFixed(0)
    },
    employeeSalary: function(){
      return this.salary * this.nbEmployeeNeeded
    },

    priceInProductionsDay : function(){
      let nbProductions = this.nbParcelles <= 1 ? this.productions.length : this.productions.length * 0.6
      let pricesArray  = []

      this.companyModel.productions.forEach(production => {
        let total = 0
        let coef = this.nbParcelles > 1 ?  production.coefStockByEmployee : 1
        //console.log("coeff IN", coef)
        production.productsIn.forEach(product => {
          //console.log("coef", coef)
          total += product.price * (production.stockIn / nbProductions * coef) * this.nbParcelles
        })
        pricesArray[production._id] = total
      })
      return pricesArray
    },
    priceOutProductionsDay : function(){
      let nbProductions = this.nbParcelles <= 1 ? this.productions.length : this.productions.length * 0.6 // - ((this.productions.length/10))
      if(nbProductions == 0) nbProductions = 1
      
      let pricesArray  = []

      this.companyModel.productions.forEach(production => {
        let coef = this.nbParcelles > 1 ?  production.coefStockByEmployee : 1
        //console.log("coeff OUT", coef)
        let g = ((production.stockOut / nbProductions) * coef * production.priceOut) * this.nbParcelles
        pricesArray[production._id] = g
      })
      return pricesArray
    },
    gainByDay: function(){
      let gain = 0
      this.companyModel.productions.forEach(production => {
        if(this.productions.indexOf(production._id) > -1){
          let g = this.priceOutProductionsDay[production._id]
          gain += g
        }
      })
      return gain.toFixed(3)
    },
    gainByMonth: function(){
      return (this.gainByDay * 30.4375).toFixed(3)
    },
    chargeByDay: function(){
      let charge = 0
      this.companyModel.productions.forEach(production => {
        if(this.productions.indexOf(production._id) > -1){
          //console.log("productsIn", production.productsIn)
          production.productsIn.forEach(product => {
            // let coef = this.nbParcelles > 1 ?  production.coefStockByEmployee : 1
            // console.log("coef", coef)
            let g = this.priceInProductionsDay[production._id] //product.price * (production.stockIn * coef) * this.nbParcelles
            //if(this.productions.indexOf(production._id) > -1){
              charge += g
              console.log("chargeByDay", product.name, "charge: "+g+" òto", product.price+" òto *", production.stockIn, charge)
          })
        }
      })
      return charge.toFixed(3)
    },
    chargeByMonth: function(){
      return (this.chargeByDay * 30.4375).toFixed(3)
    },

    mensuality: function(){
      return 0 //((this.budget*this.remiseFirstCompany) / (this.delayCredit * 12)).toFixed(2)
    },

    benefices: function(){
      return (this.gainByMonth - this.chargeByMonth - this.employeeSalary - this.mySalary - this.mensuality).toFixed(2)
    },

    nbEmployeeNeeded: function(){
      let nb = (this.surface / this.companyModel.surfaceParcelle).toFixed(0)
      return nb > 0 ? nb-1 : nb
    },
    nbParcelles: function(){
      return (this.surface / this.companyModel.surfaceParcelle).toFixed(0)
    },
    nbProdEnabled: function(){
      return this.companyModel.nbProdEnabled
    },
    nbProdEnabledChecked: function(){
      return this.productions.length >= this.companyModel.nbProdEnabled
    }
  }
}
</script>
